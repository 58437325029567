:root {
    --grid-col-spacing: 10px;
}

.grid {
    display: flex;
    max-width: 100vw;
    justify-content: left;
    flex-wrap: wrap;
}

.grid__col {
    display: inline-flex;
    min-width: fit-content;
    box-sizing: border-box;
    flex-direction: column;
    margin-right: var(--grid-col-spacing);
    margin-left: var(--grid-col-spacing);
}

.grid__col--lg-half {
    width: calc((100% - 2 * var(--grid-col-spacing) * 2) / 2);
}

.grid__col--lg-third {
    width: calc((100% - 2 * var(--grid-col-spacing) * 3) / 3);
}

.grid__col--lg-quarter {
    width: calc((100% - 2 * var(--grid-col-spacing) * 4) / 4);
}


/* Tablets */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
        .grid__col--lg-half,
        .grid__col--lg-third,
        .grid__col--lg-quarter {
            width: auto;
        }

        .grid__col--md-half {
            width: calc((100% - 2 * var(--grid-col-spacing) * 2) / 2);
        }
    }


/* Smartphones */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    }

:root {
    --site-width: 800px;
    --nav-width: 390px;
}


html {
    height: 100%;
}

body {
    display: flex;
    min-height: 100%;
    flex-flow: column nowrap;
    background: var(--color-site-bg);
}

main {
    flex: 1 1 auto;
    /* max-width: calc(var(--site-width) + 4rem); */
    width: calc(var(--site-width) + 4rem);
    margin: 2rem auto 3rem auto;
    padding:  0 0 5rem 0;
    background: white;
}

hr {
    border:none;
    border-top:1px solid lightgray;
}

.flex {
    display: flex;
}

.flex-space {
    justify-content: space-between;
}

.content-wrap {
    max-width: var(--site-width);
    margin: auto;
    padding: 0 2rem;
}
main .content-wrap{
    min-height: 500px;
}

@media (min-width:1023px) and (max-width: 1270px) {
    main {
        width: calc(100% - var(--nav-width))
    }
}

@media (min-width:1023px) and (max-width: 1600px) {
    main {
        margin: 2rem auto 3rem var(--nav-width)
    }
}

@media (max-width: 1023px) {
    header {
        position: fixed;
        width: 100vw;
    }

    main {
        width: initial;
        margin: 5rem 0 0 0;
    }
}

:root {
    --font-family: 'Roboto', sans-serif;
    --font-black: 500;
    --header-margin: 1rem;
    --paragraph-margin: 1rem;
    --home-link-font-size: 2rem;
    --nav-items-font-size: 1rem;
}

body {
    font-family: var(--font-family);
    color: var(--color-default-fg);
    line-height: 1.4em;
    /* background: lightgray; */
}

button, input, textarea {
    font-family: var(--font-family);
}

h1, h2, h3, h4, h5, h6 {
    margin: var(--header-margin) 0;
    font-weight: var(--font-black);
}

h1 {
    margin: calc(2 * var(--header-margin)) 0;
    font-size: 1.8rem;
    line-height: 2rem;
}

h2 {
    margin: calc(1.7 * var(--header-margin)) 0 var(--header-margin) 0;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

h3 {
    font-size: 1.2rem;
    line-height: 1.5rem;
}

p {
    margin: var(--paragraph-margin) 0;
}

pre {
    font-family: monospace;
}

a {
    color: var(--color-accent-blue);
    text-decoration: none;
}

a[disabled] {
    opacity: 0.7;
}

small {
    font-size: 0.8rem;
}

.doc-link {
    font-weight: 500;
}
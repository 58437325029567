@import "colors.css";

header {
    z-index: 1;
}

.header-brand {
    display: flex;
    position: relative;
    height: 25vh;
    padding: 0 1rem 0 1rem;
    /* background: #fafafa; */
    /* border-bottom: 1px solid #f0f0f0; */
    /* background: var(--color-header-bg); */
    background: var(--color-header-bg) no-repeat;
    background-size: cover;
    color: white;
    flex-direction: row;
    align-items: center;
    border-bottom: 3px solid #ffc800;
}

.header-brand a {
    color: white;
}

.header-brand h1 {
    margin: 0;
    font-size: 24px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
}

.header-brand img {
    position: relative;
    top:10px;
    width: 100px;
}

.header-brand .language-selector {
    flex: 1 1;
    text-align: right;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9em;
}

.header-brand .sigil {
    position: absolute;
    bottom: 1.3rem;
    right: 10rem;
}

.header-brand .sigil img {
    width: 160px;
}

@media (max-width:1320px) {
    .header-brand .sigil {
        bottom: 1.3rem;
        right: 8rem;
    }
    
    .header-brand .sigil img {
        width: 120px;
    }   
}


@media (max-width:1023px) {
    .header-brand {
        height: 80px;
    }
    .header-brand .language-selector {
        display: none;
    }

    .header-brand img {
        top:2px;
        left: -5px;
        width: 75px;
    }

    .header-brand .sigil {
        display: none;
    }
}

@media (max-width:760px) {
    .header-brand h1 {
        width: 300px;
        font-size: 20px;
        line-height: 22px;
    }
}

@media (max-width:500px) {
    .header-brand img {
        top:2px;
        left:-10px;
        width:60px;
    }

    .header-brand h1 {
        width: 190px;
        font-size: 16px;
        line-height: 20px;
    }

    .header-phone {
        font-size: 12px;
    }
}

@media (max-height: 400px) {
    .header-brand {
        height:59px;
    }

    .header-brand img {
        width: 60px;
    }

    .header-brand h1 {
        width:200px;
        font-size: 15px;
        line-height: 17px;
    }
}

@media (min-width:1024px) and (min-height:768px) {
    .header-brand {
        background: url("/static/images/header-bg.png") no-repeat fixed top;
        background-size: contain;
        align-items: flex-end;
    }

    .header-brand .header-phone, .header-brand .language-selector {
        display: block;
        margin-bottom: 1.5rem;
    }

    .brand-text {
        margin-left: 1rem;
    }

    .header-brand img {
        width: 120px;
    }

    .header-brand h1, .header-brand a, .header-brand span {
        text-shadow: 1px 1px black;
    }
}

.page-title {
    width: calc(100% + 4rem);
    padding: 1rem;
    box-sizing: border-box;
    margin-top: 1.6rem;
    margin-left: -2rem;
    background: #ffedad;
    border-left: 1rem solid #ffd576;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.5);
}

button, input[type="submit"], .button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9rem;
    background: var(--color-button);
    color: white;
    border-bottom: 2px solid var(--color-button-shadow);
}

button:hover, input[type="submit"]:hover, .button:hover {
    background: var(--color-button-hover);
}

.g-recaptcha {
    margin-top: 2rem;
}
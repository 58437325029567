.collapsable-article header {
    color: var(--color-accent-blue);
    font-weight: 400;
    cursor: pointer;
}


.collapsable-article header::before {
    position: relative;
    bottom:2px;
    right: 0.5rem;
    content: '›';
    font-weight: 500;
    transition: opacity 0.3s ease;
}

.collapsable-article:not(.hidden) header::before {
    opacity: 0;
}

.collapsable-article section {
    height: auto;
    margin: 1rem 0 2rem 0.5rem;
    transform: scaleY(1);
    transition: transform ease 0.2s;
    transform-origin: top;
}

.collapsable-article.hidden section {
    height: 0;
    margin: 0.5rem;
    overflow: hidden;
    transform: scaleY(0);
}

@media(max-width: 1023px) {

    .collapsable-article header {
        position: static;
        width: 100%;
    }
}
.page-content a {
    font-weight: 500;
}

.page-content b, .page-content strong {
    font-weight: 500;
}

.page-content ul, .page-content ol {
    margin-left: 1rem;
}

.page-content ul {
    list-style: initial;
}

.page-content ol {
    list-style: decimal;
}

.page-content table {
    width: 100%;
}

.page-content td, .page-content th {
    padding: 0.5rem;
    border: 1px solid lightgray;
}

.page-content th {
    background: #fafafa;
}

.page-content .no-border td{
    border: none;
    padding-left: 0;
}

.page-content img {
    width: 100%;
}
.main-page {
    /* background: blue; */
    /* background: radial-gradient(circle, var(--color-header-bg),  #144b77) fixed no-repeat; */
    /* background: url("/static/images/bg_blue.jpg") fixed no-repeat; */
    background-size: cover;
}

.main-page main {
    width: initial;
    margin: 2rem auto;
    background: none;
}

.main-page .menu-wrap {
    position: initial;
    max-width: calc(800px + 4rem);
    width: initial;
    margin: 0;
    background: none;
}

.main-page .menu {
    display: flex;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}

.main-page .menu a {
    color: white;
    text-transform: uppercase;
    /* font-weight: 900; */
}

.main-page .menu i::before {
    right:0;
    margin: 0;
    color: white;
    font-size: 4rem;
    line-height: 6rem;
}

.main-page .menu li, .main-page .menu li.has-icon {
    display: block;
    flex: 0 1 calc(33% - 2rem);
    height: 12rem;
    margin: 1rem;
    padding: 1.7rem;
    box-sizing: border-box;
    cursor: pointer;
    background: var(--color-header-bg);
}

.main-page .menu li:hover{
    /* background: #fff2c3; */
}

.main-page main .content-wrap {
    background: white;
    padding-bottom: 3rem;
}

@media (max-width:670px) {
    .main-page .menu li, .main-page .menu li.has-icon {
        flex: 0 1 calc(50% - 2rem);
        height: 12rem;
    }   
}

@media (max-width:460px) {
    .main-page .menu li, .main-page .menu li.has-icon {
        height: initial;
        padding: 0.5rem;
    }   
    .main-page .menu li a{
        font-size: 14px;
        line-height: 13px;
        margin-bottom: 0.5rem;
    }

    .main-page .menu li i{
        margin-top: -0.5rem;
    }
}

.main-page .menu a, .main-page .menu i {
    display: block;
    text-align: center;
}

.main-page .menu-button, .main-page #menu-switch {
    display: none;
}

.main-page .menu li:first-child {
    display: none;
}

.main-page .nav-child-wrap {
    display: none;
}

.main-page .language-selector {
    display: block;
    margin-right: 1rem;
}

.main-page main .language-selector {
    display: none;
}

.main .menu li i::before {
    right: 0;
}

.privacy-policy-break {
    width: 100%;
}

.main-page .menu li.privacy-policy-title {
    flex: none;
    height: auto;
    padding: 7px 10px;
}

.main-page .menu li.privacy-policy-title i, .main-page .menu li.privacy-policy-title a {
    display: inline
}

.main-page .menu li.privacy-policy-title i::before {
    display: none;
}


@media (max-width:1023px) {
    .main-page main {
        margin: 83px auto;
    }
}
nav {
    position: absolute;
    top: calc(25vh + 30px);
    left: 0;
    height: calc(75vh - 30px);
    font-size: 1.1rem;
    z-index: 10;
}



nav .hidden {
    display: none;
}

nav a {
    display: inline-block;
    padding: 0.5em 0;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

nav .language-selector {
    display: none;
    margin-left: 3rem;
    margin-bottom: 2rem;
    font-size: 0.9em;
}

.nav-child-wrap {
    position: absolute;
    top:0;
    left: var(--nav-width);
    width: calc(var(--nav-width) - 5rem);
}

.nav-child-wrap .nav-child-title {
    max-width: 250px;
    margin: 2rem 0 1rem 0;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1em;
}

.menu-underlay {
    display: none;
}

.menu-wrap {
    display: block;
    position: relative;
    min-height:350px;
    width: calc(var(--nav-width) - 2rem);
    background: var(--color-site-bg);
    overflow-x: hidden;
    z-index: 1;
}

.menu-flex{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.menu-button {
    display: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 36px;
}

.menu-button a {
    color:white;
}

.nav-back-button {
    display: block;
    position: absolute;
    top: 1.2em;
    left: -1.7em;
    width:2rem;
}

.nav-back-button::before {
    content:'‹';
    font-weight: 500;
    font-size: 3rem;
}

nav input[type="checkbox"] {
    display: none;
}

.menu {
    position: relative;
    padding: 2rem 1rem 3rem 3rem;
    transform: translate(0);
    transition: all 0.3s;
}

.menu.one-in {
    transform: translate(calc(3rem - var(--nav-width)));
}

.menu.no-anim {
    transition: none;
}

.menu li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu li.has-icon {
    padding-left: 2rem;
    margin-left: -1rem;
}

.menu li i {
    color: var(--color-accent-blue)    
}

.menu .active-menuitem i {
    color: black;
}

.menu li i::before {
    display: inline-block;
    position: relative;
    right: 10px;
}



.menu .active-menuitem {
    background: #ffd576;
    padding-left: 1rem;
    margin-left: -1rem;
}

.menu .active-menuitem a {
    color: black;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.5);
}

@media (max-width: 1023px) {
    nav {
        position: fixed;
        top: 83px;
        bottom:0;
        left: auto;
        right: 0;
        height: calc(100% - 83px);
    }

    nav .language-selector {
        display: block;
    }

    .menu .has-icon.active-menuitem {
        padding-left: 3rem;
        margin-left: -2rem;
    }

    .menu .active-menuitem {
        padding-left: 2rem;
        margin-left: -2rem;
    }

    .menu-button {
        display: block;
        position: absolute;
        top: -4.3rem;
        right: 1.5rem;
    }

    .menu-underlay {
        display: block;
        position: fixed;
        top:83px;
        bottom:0;
        left:0;
        right:0;
        pointer-events: none;
        background: black;
        opacity: 0;
        transition: opacity 0.3s;
    }

    nav .menu-wrap.menu-closed {
        width:0;
    }

    nav .menu-wrap {
        width: calc(var(--nav-width) - 2rem);
        height: 100%;
        transform: translate(100%);
        transition: transform 0.3s ease-in-out;
    }

    nav input:checked + .menu-wrap {
        width: calc(var(--nav-width) - 2rem);
        transform: translate(0);

    }

    nav input:checked ~ .menu-underlay {
        opacity: 0.5;
        pointer-events: all;
    }
}

@media (max-height: 400px) {
    nav {
        top: 60px;
        height: calc(100vh - 60px);
    }

    .menu-button {
        top: -3.7rem;
    }

    .menu-underlay {
        top: 61px;
    }
}

/* @media (max-width: 500px) {
    nav input:checked + .menu-wrap {
        width:100vw;
    }
} */
#pp-form-page-data, #pp-form-page-attachments {
    display: none;
}

.pp-wizard-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    font-size: 1.2rem;
    line-height: 1.8rem;
}

.pp-wizard-menu li {
    display: block;
    padding: 0.5rem 1rem;
    background: #f4f4f4;
    border-radius: 1.5rem;
    white-space: nowrap;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    margin-right: 1rem
}


.pp-wizard-menu .active {
    background: #e0e0e0;
    font-weight: 500;
}

.pp-wizard-step-num {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem 0 0;
    background: lightgray;
    color: black;
    border-radius: 50%;
    padding-left: 0.65rem;
    padding-top: 0.15rem;
    box-sizing: border-box;
}




.disabled-wizard-step .pp-wizard-step-num{
    color: grey;
    background: #f8f8f8
}
.pp-wizard-menu .active .pp-wizard-step-num {
    background: var(--color-accent-blue);
    color: white;
}
.documents-needed-list {
    list-style: initial;
}

.file-upload-button {
    display: inline-block;
    text-align: center;
}

.file-upload-button__disabled {
    opacity: 0.7;
}

.file-upload-button__disabled .load-spinner {
    display: inline-block;
}
.upload-info {
    font-weight: 600;
    font-size: 13px;
}

#photo_field {
    display: none;
}
#finalize{
    padding: 10px 15px;
    margin-left: 10px;
    background: red;
    border-bottom: 2px solid darkred;
}
#finalize:hover{
    background: #ff3333;
}


.lds-dual-ring {
    display: none;
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;
}
.lds-dual-ring:after {
    display: block;
    width: 14px;
    height: 14px;
    margin: 1px;
    content: " ";
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.finalized-info {
    display: none;
}

#parking-docs {
    display: block;
    position: relative;
    bottom: 1.7rem;
}

.form-status-error {
    background: coral;
}
#map {
    height: 100%;
}

.map__overlay #zoneid, .map__overlay #fee {
    font-weight: 500;
}

.map__overlay {
    display: flex;
    position: fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:60px;
    flex-direction: row;
    align-items: center;
    background: white;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.map__exit-button {
    /* font-size: 1rem; */
    margin-left: 2rem;
    white-space: nowrap;
}

.map__info {
    margin-left: 2rem;
}

.map__info label:not(:first-child) {
    margin-left: 1rem;
}

.map__zone-info {
    display: none;
}

.map_zone--protected {
    display: none;
}

.map__info.has-content .map__hint{
    display: none;
}

.map__info.has-content .map__zone-info{
    display: block;
}

.map__info.has-content .map__zone--protected{
    display: none;
}

.map__info.has-content.protected .map__zone-info{
    display: none;
}

.map__info.has-content.protected .map_zone--protected{
    display: block;
}

.map__exit-button::before {
    position: relative;
    top:4px;
    right: 4px;
    content:'‹';
    font-size: 2.5rem;
    line-height: 0;
}

.map-margin-right {
    margin-right:1rem;
}

.map__overlay #time {
    white-space: nowrap;
    margin-right: 1rem;
    font-size: 0.8rem;
}

@media (max-width: 480px) {
    .map__logo {
        display: none;
    }
}

@media (max-width: 470px) {
    .map__zone-info {
        font-size: 12px;
    }
}
.form {
    display: flex;
    margin: auto;
    flex-flow: row wrap;
    /* justify-content: center; */
}

.form * {
    box-sizing: border-box;
}


.form-block-title {
    flex: 1 1 100%;
    text-transform: capitalize;
    margin-top: 3rem;
    /* border-bottom: 1px solid rgb(236, 236, 236); */
}

.form h2:first-of-type.form-block-title {
    margin-top: 0.5rem;
}

.form__field {
    position: relative;
    flex: 0 1 50%;
    margin: 1rem 0;
    white-space: nowrap;
}

.form__field input, .form__field select, .form__field textarea {
    width: calc(100% - 2rem);
    padding: 0.6rem 0.8rem;
    background: #fafafa;
    border: 1px solid lightgray;
    font-size: 1rem;
}

.form__field textarea {
    height: 100px;
}

.form__field input[type="date"] {
    font-family: var(--font-family)
}

.form__field label{
    display: block;
    font-weight: var(--font-black);
}

.form__field__help {
    display: block;
    position: absolute;
    top:0;
    right:2rem;
    text-align: right;
    font-size: 0.75rem;
    white-space: nowrap;
}

.form__field .errorlist {
    color: var(--color-error);
    font-weight: var(--font-black);
    font-size: 0.75rem;
    margin-top: 0.2rem;
}

.form__submit {
    width: 100%;
    margin: 1rem 0 2rem 0;
}

.form__submit button[type="submit"] {
    margin-top: 1rem;
}

@media (max-width: 780px) {
    .form__field {
        flex: 0 1 350px;
    }
    .form__field input, .form__field select, .form__field textarea {
        width: 100%;
    }
    .form__field__help {
        right:0;
    }
}

.grecaptcha-badge {
    height: 5px !important;
    opacity: 0;
    overflow: hidden;
}

.form-photos {
    display: flex;
    margin-top: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-photos li {
    display: block;
    padding: 10px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 1px solid lightgray;
}

.photo-thumb {
    display: inline-block;
    width: 130px;
    height: 130px;
    background-size: cover;
    background-position: 50% 50%;
}

.photo-name {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-status-row {
    display: inline-block;
    position: relative;
    top: 0.8rem;
    left: -1rem;
    padding: 0.25rem 0.5rem;
    background: #cbf5cb;
    border-radius: 3px;

}

